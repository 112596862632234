.paginator {
  margin: 0.125rem;
  margin-bottom: 0;
  margin-top: 0.5rem - 0.125rem;
  align-items: flex-end;

  .more-item {
    width: 2.25rem;
    height: 2.25rem;
    margin: 0.25rem;
    margin-bottom: 0;
    font-weight: bold;
    display: flex;
    opacity: 0.5;
  }

  .button {
    margin: 0.125rem;
    font-weight: bold;
    height: 2.25rem;
    border-radius: 0.5rem;
    font-size: 0.85rem;

    &:not(.not-square) {
      padding: 0.25rem;
      min-width: 2.25rem;
    }

    &:not(.is-primary-gradient) {
      background: none;
      @include themed {
        color: th("text");
      }
    }

    &.not-square {
      &:not(:disabled) {
        color: $primary-600;
      }
    }
    // no opacity
    border: 1px solid #00000000;
    &:hover {
      &:not(:disabled) {
        @include themed {
          border: 1px solid th("border-color");
        }
      }
    }
  }

  .next-icon {
    margin: 0;
    margin-right: -0.75rem;
  }

  .previous-icon {
    margin: 0;
    margin-left: -0.75rem;
  }
}
