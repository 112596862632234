.top-bar {
  padding: 16px;
  min-width: 100vw;
  position: fixed;
  height: 75px;
  z-index: 1000;

  font-size: 22px;
  display: flex;
  flex-direction: row;

  @include themed {
    background: th("bg");
  }

  .nav-logo {
    font-size: 20px;
    display: flex;
    flex-direction: row;

    @include themed {
      color: th("text");
    }

    .ai-square {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-size: 18px;

      background: linear-gradient(-45deg, $primary-900, $primary-600);
      width: 28px;
      height: 28px;
      margin-left: 5px;
      margin-top: -2px;
    }
  }

  .nav-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .ButtonLink,
    .TextLink {
      font-size: 18px;
    }

    .ButtonLink {
      margin-left: 16px;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
