.overlay-navbar {
  height: calc(100% - 64px);
  width: 100%;
  position: fixed;
  backdrop-filter: blur(5px);

  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.main-container-navbar {
  position: relative;
  padding-left: 16rem;
  width: 100%;
  height: 100%;
}

.main-container-fullscreen {
  position: relative;
  width: 100%;
  height: 100%;
}

.navbar-logo {
  font-size: 1.5rem;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  // Micro hr adjusting
  height: calc(4.25rem - 4px);
  min-height: calc(4.25rem - 4px);

  @include themed {
    color: th("text");
  }

  .link-text {
    font-family: "Poppins", sans-serif;
    transition: 0.15s;

    @include themed {
      color: th("text");
    }

    h1 {
      opacity: 0.75;
    }
    &:hover {
      h1 {
        opacity: 1;
      }
    }
  }
}

.navbar {
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1001;
  position: fixed;
  width: 16rem;
  overflow: auto;

  scrollbar-width: thin;

  @include themed {
    background-color: th("bg");
    border-right: 1px solid th("border-color");
    scrollbar-color: th("border-color") transparent;
  }

  hr {
    margin: 0.75rem 4rem;

    &:first-child {
      margin-top: 0.25rem;
    }

    height: 1px;

    @include themed {
      background-color: th("border-color");
    }
  }

  .navbar-home {
    flex: 1;
  }

  .nav-dropdown-container {
    width: calc(100% - 2rem);
    margin: 0 1rem 0.5rem;
  }

  .nav-item {
    list-style: none;
    cursor: pointer;

    .nav-link {
      text-decoration: none;
      display: flex;
      align-items: center;

      transition: filter 300ms ease;
      transition: background-color 300ms ease;

      margin: 0 1rem 0.25rem;
      border-radius: 0.5rem;

      .link-text {
        font-size: 0.9em;
        white-space: nowrap;
        margin-left: -0.5rem;
        @include themed {
          color: th("text");
        }
      }

      .msymbol {
        text-align: left;
        margin: 0.5rem 0;
        width: 4.25rem;
        padding-left: 0.75rem;
        font-size: 1.8em;
        transition: opacity 0.3s;
        opacity: 0.25;

        background: -moz-linear-gradient(
          -135deg,
          $primary-600 0%,
          $primary-900 100%
        );
        background: -webkit-linear-gradient(
          -135deg,
          $primary-600 0%,
          $primary-900 100%
        );
        background: linear-gradient(
          -135deg,
          $primary-600 0%,
          $primary-900 100%
        );
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:not(.disabled) {
        &:not(.active) {
          &:hover {
            @include themed {
              background-color: th("bg-hover");
            }

            .msymbol {
              opacity: 0.5;
            }
          }
        }
      }

      &.active {
        @include themed {
          background-color: th("bg-selected");
        }

        .msymbol {
          opacity: 1;
        }
      }
    }
  }
}
.navbar-logout {
  margin-bottom: 0.5rem;

  .dropdown-item {
    margin: 0;
    padding: 0;
    position: relative;

    .dropdown-link {
      margin: 0 0.5rem;
      padding: 0 0.5rem;
      display: flex;
      height: 40px;
      flex-direction: row;
      align-items: center;
      border-radius: 5px;
      @include themed {
        background-color: th("bg-panel");
      }
      &:not(:active) {
        &:hover {
          @include themed {
            background-color: th("bg-hover");
          }
        }
      }

      &:active {
        @include themed {
          background-color: th("bg-selected");
        }
      }

      .msymbol {
        margin-right: 1rem;
        opacity: 1;

        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.language-selector {
  .dropdown-button {
    padding: 0 0.8rem;
  }
}
.navbar,
.language-overlay {
  .language-selector {
    .dropdown-button {
      padding: 0 0.5rem;
    }
  }
}

.language-selector {
  .dropdown-menu {
    overflow: auto;
  }

  .dropdown-button {
    font-family: "Poppins", sans-serif !important;
    font-weight: 600;

    @include themed {
      border: 1px solid th("border-color") !important;
    }

    &:hover {
      @include themed {
        border: 1px solid th("border-color-hover") !important;
      }
    }
  }
  .dropdown-item {
    margin: 0;
    padding: 0;
    position: relative;

    .dropdown-link {
      margin: 0 0.5rem;
      padding: 0 0.5rem;
      padding-left: 0.25rem;
      display: flex;
      height: 40px;
      flex-direction: row;
      align-items: center;
      font-size: 1rem;
      border-radius: 5px;
      font-family: "Poppins", sans-serif !important;
      cursor: pointer;

      @include themed {
        background-color: th("bg-panel");
      }

      &.selected {
        font-weight: 800;

        @include themed {
          background-color: th("bg-selected");
        }
      }

      &:not(.disabled) {
        &:not(.selected) {
          &:not(:active) {
            &:hover {
              @include themed {
                background-color: th("bg-hover");
              }
            }
          }
        }

        &:active {
          @include themed {
            background-color: th("bg-selected");
          }
        }
      }

      .msymbol {
        margin-right: 1rem;
        opacity: 1;
      }
    }
  }
}

.dropdown-menu {
  .dropdown-item {
    margin: 0;
    padding: 0;
    position: relative;

    .dropdown-link {
      margin: 0 0.5rem;
      padding: 0 0.5rem;
      padding-left: 0.25rem;
      display: flex;
      height: 40px;
      flex-direction: row;
      align-items: center;
      font-size: 1rem;
      border-radius: 5px;
      font-family: "Poppins", sans-serif !important;
      cursor: pointer;

      @include themed {
        background-color: th("bg-panel");
      }

      &.selected {
        font-weight: 800;

        @include themed {
          background-color: th("bg-selected");
        }
      }

      &:not(.disabled) {
        &:not(:active) {
          &:not(.selected) {
            &:hover {
              @include themed {
                background-color: th("bg-hover");
              }
            }
          }
        }

        &:active {
          @include themed {
            background-color: th("bg-selected");
          }
        }
      }

      &.disabled {
        opacity: 0.25;
        cursor: not-allowed;
      }

      .msymbol {
        margin-right: 1rem;
        opacity: 1;
      }
    }
  }
}

.navbar-logout {
  .dropdown-item {
    .dropdown-link.logout {
      background-color: #c0392b22;

      &:not(:active) {
        &:hover {
          background-color: #c0392b44;
        }
      }

      &:active {
        background-color: #c0392b66;
      }

      .msymbol {
        background: $red-dark;

        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
.main-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: auto;

  scrollbar-width: thin;

  @include themed {
    scrollbar-color: th("border-color") transparent;
  }
}

.language-overlay {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 1000;

  button {
    width: 100%;
  }
}

// remove button style
.nav-link,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.bottombar {
  display: none !important;
  z-index: 1001;

  position: fixed;
  height: 64px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;

  @include themed {
    background: th("bg");
    border-top: 1px solid th("border-color");
  }

  .nav-item {
    height: 100%;
    width: 25%;
    align-items: center;
    justify-content: center;
    text-align: center;

    list-style: none;
    cursor: pointer;

    transition: filter 300ms ease;
    transition: background-color 300ms ease;

    border-radius: 0.5rem;

    @include themed {
      color: th("text-light");
    }

    .link-text {
      font-size: 12px;
    }

    .msymbol {
      margin-bottom: 0.25rem;
      width: 4.25rem;
      font-size: 1.8em;
      transition: opacity 0.3s;
      opacity: 0.25;

      background: -moz-linear-gradient(
        -135deg,
        $primary-600 0%,
        $primary-900 100%
      );
      background: -webkit-linear-gradient(
        -135deg,
        $primary-600 0%,
        $primary-900 100%
      );
      background: linear-gradient(-135deg, $primary-600 0%, $primary-900 100%);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &:not(.disabled) {
      &:not(.active) {
        &:hover {
          @include themed {
            background-color: th("bg-hover");
            color: th("text");
          }

          .msymbol {
            opacity: 0.5;
          }
        }
      }
    }

    &.active {
      @include themed {
        background-color: th("bg-selected");
        color: th("text");
      }

      .msymbol {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .navbar-logo {
    font-size: 1.25rem;
  }
  .main-container-navbar {
    padding-left: 0;
  }

  .language-overlay {
    bottom: 0.75rem;
    right: 1rem;
    width: calc(100% - 2rem);
  }

  .main-content {
    height: calc(100% - 64px);
    margin-bottom: 64px;
  }

  .navbar {
    height: calc(100% - 64px);

    &:not(.is-open) {
      display: none;
    }
  }

  .bottombar {
    display: flex !important;
  }
}
