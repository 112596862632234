.button {
  cursor: pointer;
  border-radius: 0.4rem;
  font-family: "Poppins", sans-serif;

  &.is-active,
  &:active {
    &:not(:disabled) {
      box-shadow: 0 0 0 0.125em #8548c740;
    }
  }

  &.is-primary {
    font-weight: bold;
    background: $primary-600;
    border: none;

    &:hover {
      background: $primary-500;
      opacity: 0.85;
      border: none;
    }
    &:active {
      background: $primary-400;
      opacity: 0.75;
      border: none;
    }

    &:focus {
      background: $primary-500;
      opacity: 0.75;
      border: none;
    }

    &:disabled {
      background: $primary-600;
      border: none;
      opacity: 0.5;
    }
  }

  &.secondary {
    background-color: $secondary;
  }
  &.danger {
    background-color: $danger;
  }
  &.warning {
    background-color: $warning;
  }
  &.info {
    background-color: $info;
  }
  &.link {
    background-color: $link;
  }
  &.success {
    background-color: $success;
  }
  &.transparent {
    background-color: transparent;
  }

  &.is-bordered {
    font-weight: 600;

    @include themed {
      color: th("text-light");
      border: 1px solid th("border-color");
      background: th("bg-panel");
    }

    &:not(:disabled) {
      &:hover {
        @include themed {
          color: th("text");
          border: 1px solid th("border-color-hover");
        }
      }

      &:active {
        border: 1px solid $primary;
      }
    }

    &:disabled,
    &.disabled {
      background: transparent;
    }
  }

  &.is-primary-gradient {
    font-weight: bold;
    color: #fff;
    border: none;

    // background: linear-gradient(135deg, $primary-700 0%, $primary-950 100%);
    @include themed {
      background: th("gradient-primary");
    }

    &:hover {
      opacity: 0.85;
      border: none;
    }
    &:active {
      opacity: 0.75;
      border: none;
    }

    &:focus {
      opacity: 0.75;
      border: none;
    }

    &:disabled {
      border: none;
      opacity: 0.5;
    }
  }

  &.is-warn-gradient {
    font-weight: bold;
    color: #fff;
    border: none;

    @include themed {
      background: th("gradient-warn");
    }

    &:hover {
      opacity: 0.85;
      border: none;
    }
    &:active {
      opacity: 0.75;
      border: none;
    }

    &:focus {
      opacity: 0.75;
      border: none;
    }

    &:disabled {
      border: none;
      opacity: 0.5;
    }
  }


  .icon:last-child:not(:first-child){
    margin-right: unset;
  }
}
