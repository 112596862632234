.table-list {
  margin-bottom: 10px;
  border-radius: 0.5rem;
  overflow-y: auto;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include themed {
    border: 1px solid th("border-color");
  }
}

.table-item {
  padding: 0.4rem 1rem;
  height: 50px;
  min-width: 100%;
  display: inline-flex;
  align-items: center;

  @include themed {
    color: th("text-light");
  }

  &:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
  }
  &.odd {
    @include themed {
      background: th("bg");
    }
  }

  &.even {
    @include themed {
      background: th("bg-panel");
    }
  }

  &.header-item {
    border-radius: 0.5rem 0.5rem 0 0;
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-size: 0.95rem;
    height: 35px;

    @include themed {
      border-bottom: 1px solid th("border-color");
      background: th("bg");
      color: th("text");
    }
  }
}

.fake-header {
  height: 35px;
  width: 10rem;

  @include themed {
    border-bottom: 1px solid th("border-color");
  }
}
.overlay-option-button {
  position: absolute;
  right: 0;
  height: 50px;
  justify-content: center;
  width: 10rem;
  text-align: right;
  font-weight: bold;
  padding-left: 7.5px;
  padding-right: 7.5px;

  display: flex;
  flex-direction: column;

  &:last-child {
    border-radius: 0 0 0.5rem 0;
  }

  &.odd {
    @include themed {
      background: th("bg");
    }
  }

  &.even {
    @include themed {
      background: th("bg-panel");
    }
  }
}

.infos-chart {
  width: 100%;
  flex-direction: row;
  display: flex;
}

.infos-container {
  max-width: 350px;
  width: 100%;
  flex-direction: column;
  display: flex;
}

.usage-chart-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc(100% - 350px);
  padding-left: 1rem;
  justify-content: flex-end;
}

.usage-chart-wrapper {
  width: 100%;
  position: relative;
  height: calc(278px - 42px - 16px);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 0.75rem;
}

@media screen and (max-width: 1280px) {
  .usage-chart-container {
    max-width: 100%;
    width: 100%;
    padding-left: 0rem;
  }

  .usage-chart-wrapper {
    height: 175px;
  }

  .infos-chart {
    flex-direction: column;
  }
}
