.animated-loader-container {
  min-height: 280px;
  opacity: 0;
  animation: 0.7s ease-in-out 0.7s forwards fadeInTop;
}

.animated-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  opacity: 0.25;
}
.animated-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #555;
  border-radius: 50%;
  animation: animated-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #555 transparent transparent transparent;
}
.animated-loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.animated-loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.animated-loader div:nth-child(3) {
  animation-delay: -0.15s;
}

.animated-loader.small {
  width: 50px;
  height: 50px;
}
.animated-loader.small div {
  width: 34px;
  height: 34px;
  margin: 8px;
  border: 4px solid #ccc;
  border-radius: 50%;
  border-color: #ccc transparent transparent transparent;
}

.animated-loader.smaller {
  width: 40px;
  height: 40px;
}
.animated-loader.smaller div {
  width: 24px;
  height: 24px;
  margin: 8px;
  border: 3px solid #ccc;
  border-radius: 50%;
  border-color: #ccc transparent transparent transparent;
}

@keyframes animated-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
