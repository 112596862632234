.fade-in-top {
  animation: 0.7s fadeInTop ease forwards;
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-0.25rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
