@import "../imports/tailwind-colors.scss";

$primary-50: #f5f3ff;
$primary-100: #ede9fe;
$primary-200: #ddd6fe;
$primary-300: #c4b5fd;
$primary-400: #a78bfa;
$primary-500: #8b5cf6;
$primary-600: #7c3aed;
$primary-700: #6d28d9;
$primary-800: #5b21b6;
$primary-900: #4c1d95;
$primary-950: #2e1065;

$alpha-primary-50: #f5f3ff66;
$alpha-primary-100: #ede9fe66;
$alpha-primary-200: #ddd6fe66;
$alpha-primary-300: #c4b5fd66;
$alpha-primary-400: #a78bfa66;
$alpha-primary-500: #8b5cf666;
$alpha-primary-600: #7c3aed66;
$alpha-primary-700: #6d28d966;
$alpha-primary-800: #5b21b666;
$alpha-primary-900: #4c1d9566;
$alpha-primary-950: #2e106566;

$alpha02-primary-50: #f5f3ff22;
$alpha02-primary-100: #ede9fe22;
$alpha02-primary-200: #ddd6fe22;
$alpha02-primary-300: #c4b5fd22;
$alpha02-primary-400: #a78bfa22;
$alpha02-primary-500: #8b5cf622;
$alpha02-primary-600: #7c3aed22;
$alpha02-primary-700: #6d28d922;
$alpha02-primary-800: #5b21b622;
$alpha02-primary-900: #4c1d9522;
$alpha02-primary-950: #2e106522;

$alpha01-primary-50: #f5f3ff11;
$alpha01-primary-100: #ede9fe11;
$alpha01-primary-200: #ddd6fe11;
$alpha01-primary-300: #c4b5fd11;
$alpha01-primary-400: #a78bfa11;
$alpha01-primary-500: #8b5cf611;
$alpha01-primary-600: #7c3aed11;
$alpha01-primary-700: #6d28d911;
$alpha01-primary-800: #5b21b611;
$alpha01-primary-900: #4c1d9511;
$alpha01-primary-950: #2e106511;

$yellow-50: #fffbeb;
$yellow-100: #fdf2c8;
$yellow-200: #fce38b;
$yellow-300: #facf4f;
$yellow-400: #f9bb26;
$yellow-500: #f39c12;
$yellow-600: #d77408;
$yellow-700: #b2510b;
$yellow-800: #913e0f;
$yellow-900: #773310;
$yellow-950: #441904;

$alpha02-yellow-50: #fffbeb22;
$alpha02-yellow-100: #fdf2c822;
$alpha02-yellow-200: #fce38b22;
$alpha02-yellow-300: #facf4f22;
$alpha02-yellow-400: #f9bb2622;
$alpha02-yellow-500: #f39c1222;
$alpha02-yellow-600: #d7740822;
$alpha02-yellow-700: #b2510b22;
$alpha02-yellow-800: #913e0f22;
$alpha02-yellow-900: #77331022;
$alpha02-yellow-950: #44190422;

$teal-light: #1abc9c;
$teal-dark: #16a085;
$green-light: #2ecc71;
$green-dark: #27ae60;
$green-dark-alpha: rgba(39, 174, 96, 0.2);
$blue-light: #3498db;
$blue-light-alpha: rgb(51, 152, 219, 0.2);
$blue-dark: #2980b9;
$violet-light: #9b59b6;
$violet-dark: #8e44ad;
$dark-light: #34495e;
$dark-dark: #2c3e50;
$yellow-light: #f1c40f;
$yellow-dark: #f39c12;
$yellow-dark-alpha: rgba(243, 156, 18, 0.2);
$orange-light: #e67422;
$orange-light-alpha: rgba(230, 96, 34, 0.2);
$orange-dark: #d35400;
$orange-dark-alpha: rgba(211, 84, 0, 0.2);
$red-light: #e74c3c;
$red-dark: #c0392b;
$red-dark-alpha: rgba(192, 57, 43, 0.2);
$white-light: #e0e0e5;
$white-dark: #bdc3c7;
$grey-light: #95a5a6;
$grey-dark: #7f8c8d;

$danger: $red-light;
$warning: $yellow-light;
$success: $green-light;
$info: $blue-light;
$link: $violet-light;

$full-white: #fff;
$full-black: #000;

/*3 COLORS THEME*/

$border-color-hover: rgba(255, 255, 255, 0.25);
$border-color: rgba(255, 255, 255, 0.15);
$border-input-color: rgba(255, 255, 255, 0.075);

$bg: rgb(10, 15, 20);
$bg-panel: rgb(15, 20, 25);
$text: #eeeeee;
$text-light: #cccccc;

$bg-hover: rgba(255, 255, 255, 0.015);
$bg-selected: rgba(255, 255, 255, 0.03);

$primary: $primary-600;
$secondary: $sky-600;

// @import "../imports/tailwind-colors.scss";

// $primary-50: #f5f3ff;
// $primary-100: #ede9fe;
// $primary-200: #ddd6fe;
// $primary-300: #c4b5fd;
// $primary-400: #a78bfa;
// $primary-500: #8b5cf6;
// $primary-600: #7c3aed;
// $primary-700: #6d28d9;
// $primary-800: #5b21b6;
// $primary-900: #4c1d95;
// $primary-950: #2e1065;

// $alpha-primary-50: #f5f3ff66;
// $alpha-primary-100: #ede9fe66;
// $alpha-primary-200: #ddd6fe66;
// $alpha-primary-300: #c4b5fd66;
// $alpha-primary-400: #a78bfa66;
// $alpha-primary-500: #8b5cf666;
// $alpha-primary-600: #7c3aed66;
// $alpha-primary-700: #6d28d966;
// $alpha-primary-800: #5b21b666;
// $alpha-primary-900: #4c1d9566;
// $alpha-primary-950: #2e106566;

// $alpha02-primary-50: #f5f3ff22;
// $alpha02-primary-100: #ede9fe22;
// $alpha02-primary-200: #ddd6fe22;
// $alpha02-primary-300: #c4b5fd22;
// $alpha02-primary-400: #a78bfa22;
// $alpha02-primary-500: #8b5cf622;
// $alpha02-primary-600: #7c3aed22;
// $alpha02-primary-700: #6d28d922;
// $alpha02-primary-800: #5b21b622;
// $alpha02-primary-900: #4c1d9522;
// $alpha02-primary-950: #2e106522;

// $yellow-50: #fffbeb;
// $yellow-100: #fdf2c8;
// $yellow-200: #fce38b;
// $yellow-300: #facf4f;
// $yellow-400: #f9bb26;
// $yellow-500: #f39c12;
// $yellow-600: #d77408;
// $yellow-700: #b2510b;
// $yellow-800: #913e0f;
// $yellow-900: #773310;
// $yellow-950: #441904;


// $teal-light: #1abc9c;
// $teal-dark: #16a085;
// $green-light: #2ecc71;
// $green-dark: #27ae60;
// $green-dark-alpha: rgba(39, 174, 96, 0.22);
// $blue-light: #3498db;
// $blue-dark: #2980b9;
// $violet-light: #9b59b6;
// $violet-dark: #8e44ad;
// $dark-light: #34495e;
// $dark-dark: #2c3e50;
// $yellow-light: #f1c40f;
// $yellow-dark: #f39c12;
// $yellow-dark-alpha: rgb(243, 156, 18, 0.2);
// $orange-light: #e67e22;
// $orange-dark: #d35400;
// $red-light: #e74c3c;
// $red-dark: #c0392b;
// $red-dark-alpha: rgba(192, 57, 43, 0.2);
// $white-light: #e0e0e5;
// $white-dark: #bdc3c7;
// $grey-light: #95a5a6;
// $grey-dark: #7f8c8d;

// $danger: $red-light;
// $warning: $yellow-light;
// $success: $green-light;
// $info: $blue-light;
// $link: $violet-light;

// $full-white: #fff;
// $full-black: #000;

// /*3 COLORS THEME*/

// $border-color-hover: rgba(0, 0, 0, 0.25);
// $border-color: rgba(0, 0, 0, 0.15);
// $border-input-color: rgba(0, 0, 0, 0.075);


// $bg: rgb(235, 240, 245);
// $bg-panel: rgb(245, 250, 255);
// $text: #444;
// $text-light: #666;

// $bg-hover: rgba(0, 0, 0, 0.03);
// $bg-selected: rgba(0, 0, 0, 0.06);

// $primary: $primary-600;
// $secondary: $sky-600;
