.amount-bar {
  width: 100%;
  display: grid;
  grid-gap: 0.5rem;

  &.item-per-line-2 {
    grid-template-columns: repeat(auto-fill, minmax(275px, auto));
  }

  &.item-per-line-4 {
    grid-template-columns: repeat(auto-fill, minmax(275px, auto));
  }
}

.amount-labeled {
  .infos-credits {
    margin: 0;
    margin-bottom: 0;
    width: 100%;
    text-align: left;
    font-weight: 300;

    @include themed {
      color: th("text-light");
    }

    .infos-creits-label {
      font-size: 16px;
      line-height: 20px;

      @include themed {
        color: th("text-light");
      }
    }
  }

  .balance-text {
    font-family: "Poppins", sans-serif;
    width: 100%;
    text-align: left;
    margin-top: 2px;

    @include themed {
      color: th("text-light");
    }
    .balance-amount {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.amount-icon-container {
  &:not(.primary){
  @include themed {
    background: th("gradient-primary-red-alpha");
  }

  .amount-icon {
    @include themed {
      background: th("gradient-primary-red");
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}


  &.yellow-red {
    @include themed {
      background: th("gradient-yellow-red-alpha");
    }

    .amount-icon {
      @include themed {
        background: th("gradient-yellow-red");
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &.primary {
    @include themed {
      background: th("gradient-primary-alpha");
    }

    .amount-icon {
     color: $primary;
    }
  }
}

@media screen and (max-width: 600px) {
  .amount-bar {
    grid-gap: 0.5rem;

    &.item-per-line-2 {
      grid-template-columns: repeat(auto-fill, 100%);
    }

    &.item-per-line-4 {
      grid-template-columns: repeat(auto-fill, 100%);
    }
  }
}
