$themes: (
  "dark": (
    bg: rgb(10, 15, 20),
    bg-panel: rgb(15, 20, 25),
    text: #eeeeee,
    text-light: #cccccc,
    bg-hover: rgba(255, 255, 255, 0.015),
    bg-selected: rgba(255, 255, 255, 0.03),
    border-color-hover: rgba(255, 255, 255, 0.25),
    border-color: rgba(255, 255, 255, 0.15),
    border-input-color: rgba(255, 255, 255, 0.15),
    alpha-text: rgba(255, 255, 255, 0.15),
    gradient-primary:
      linear-gradient(135deg, $primary-700 0%, $primary-950 100%),
    gradient-primary-alpha:
      linear-gradient(
        135deg,
        $alpha02-primary-700 0%,
        $alpha02-primary-950 100%
      ),
    gradient-warn: linear-gradient(135deg, $yellow-500 0%, $yellow-800 100%),
    gradient-yellow-red: linear-gradient(135deg, $yellow-600 0%, #940d2c 100%),
    gradient-yellow-red-alpha:
      linear-gradient(135deg, $alpha02-yellow-600 0%, #940d2c22 100%),
    gradient-primary-red: linear-gradient(135deg, $primary-700 0%, #800b5d 100%),
    gradient-primary-red-alpha:
      linear-gradient(135deg, $alpha02-primary-700 0%, #800b5d22 100%),
    tier-0-color: #ffffff,
    console-color: rgba(12, 12, 12, 0.5),
  ),
  "light": (
    bg: rgb(240, 245, 250),
    bg-panel: rgb(245, 250, 255),
    text: #444,
    text-light: #666,
    bg-hover: rgba(0, 0, 0, 0.03),
    bg-selected: rgba(0, 0, 0, 0.06),
    border-color-hover: rgba(0, 0, 0, 0.25),
    border-color: rgba(0, 0, 0, 0.15),
    border-input-color: rgba(0, 0, 0, 0.15),
    alpha-text: rgba(0, 0, 0, 0.3),
    gradient-primary:
      linear-gradient(135deg, $primary-500 0%, $primary-800 100%),
      gradient-primary-alpha:
      linear-gradient(
        135deg,
        $alpha02-primary-500 0%,
        $alpha02-primary-800 100%
      ),
    gradient-warn: linear-gradient(135deg, $yellow-400 0%, $yellow-600 100%),
    gradient-yellow-red: linear-gradient(135deg, $yellow-500 0%, #da1039 100%),
    gradient-yellow-red-alpha:
      linear-gradient(135deg, $alpha02-yellow-500 0%, #da103922 100%),
    gradient-primary-red: linear-gradient(135deg, $primary-700 0%, #c8116d 100%),
    gradient-primary-red-alpha:
      linear-gradient(135deg, $alpha02-primary-700 0%, #c8116d22 100%),
    tier-0-color: #222222,
    console-color: rgba(12, 12, 12, 0.75),
  ),
);

// génère un nouveau mixin nommé themed.
// L'idée est que toutes les règles css définie à l'interieur
// seront dupliquée en dark et light.
@mixin themed() {
  // pour chaque thème défini dans la variable $themes
  @each $theme, $map in $themes {
    // défini un nouveau matcher .theme--nomdutheme le-matcheur-courant
    .theme--#{$theme} & {
      // defini une variable globale contenant un hash
      // de toutes les valeurs courantes du theme
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      // execute le contenu original du mixin
      @content;
      // supprime la variable globale
      $theme-map: null !global;
    }
  }
}

// Nouvelle fonction t qui va aller lire la valeur
// de la clé dans le hash temporaire défini par le mixin
@function th($key: "base0") {
  @return map-get($theme-map, $key);
}
