.auth-container {
  align-items: center;
  justify-content: flex-start;
  margin-top: 75px;
}

.login-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: calc(100% - 1rem);
  max-width: 450px;
  margin: 0 0.5rem;
  margin-top: 7rem;
  margin-bottom: 1rem;

  .image-logo-container {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-text {
    margin-bottom: 0.5rem;
    font-size: 1.75rem;
    
    @include themed {
      color: th("text-light");
    }
  }

  .logo-image {
    height: 4.25rem;
  }
}

.login-box {
  max-width: 450px;
  width: calc(100% - 1rem);
  margin: 0 0.5rem;
  padding-bottom: 0.75rem !important;
}

.login-button {
  width: 100%;
  margin-top: 0.5rem;
}

.auth-link {
  margin-top: 1rem;
  margin-bottom: 1rem;
  opacity: 0.75;
  user-select: none;
  background: none;
  
  @include themed {
    color: th("text-light");
  }

  &:hover {
    opacity: 1;
  }

  &:focus,
  &:active {
    opacity: 1;
    color: $primary;
  }
}

@media screen and (max-width: 600px) {
  .login-title {
    margin-top: 0;
  }
}
