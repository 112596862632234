.modal-wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  backdrop-filter: blur(5px);

  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.modal-container {
  z-index: 10000;
  position: fixed;

  padding: 1rem;
  padding-bottom: 0;
  width: calc(100% - 2rem);
  max-height: calc(100% - 2rem);
  margin: 1rem;
  max-width: 500px;
  border-radius: 8px;
  
  @include themed {
    color: th("text");
    background: th("bg-panel");
    border: 1px solid th("border-color");
  }
}

.modal-title {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.modal-text {
}

.modal-button {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
