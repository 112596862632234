.api-keys {
  .infos-api-keys {
    margin: 0;
    width: 100%;
    text-align: left;
    font-weight: bold;

    margin-bottom: 0.5rem;

    @include themed {
      color: th("text-light");
    }
  }

  .button-div {
    width: 100%;
    text-align: left;
    margin-top: 0.5rem;
  }
}

.key-block {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border-radius: 8px;

  width: 100%;

  @include themed {
    background: th("bg");
  }
}

.msymbol.danger {
  margin-right: 1rem;
  opacity: 1;
  background: $red-dark;
  opacity: 0.5;

  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
