.TextLink {
  padding: 8px 16px;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: 0.15s;
  font-weight: 600;

  @include themed {
    color: th("text-light");
  }

  &:hover {
    color: $primary-600;
  }

  &:active {
    color: $primary-600;
  }
}
