.ResiliateSubscription {
  li {
    display: flex;
    align-items: center;

    font-family: "Poppins", sans-serif;
    margin-bottom: 0.5rem;
    cursor: pointer;

    @include themed {
      color: th("text-light");
    }

    .indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 16px;
      margin: 0.5rem 0;
      margin-right: 1rem;

      @include themed {
        border: 1px solid th("border-color");
      }

      &:active,
      &.active {
        background: $primary;
        color: #fff;
        border: 1px solid $primary;
      }

      .material-symbols-outlined {
        font-size: 16px;
      }
    }

    &:hover {
      @include themed {
        color: th("text");
      }

      .indicator {
        border: 1px solid $primary-800;
      }
    }
  }
}
