.Translate {
  height: 100% !important;
  max-height: 100% !important;
  position: relative;

  .language-buttons {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;

    .file-type-selector {
      width: 175px;
    }

    .button-div {
      width: calc(50% - 0.5rem);
      text-align: left;
      justify-content: flex-start;

      &:first-child {
        margin-right: 0.5rem;
      }
      &:last-child {
        margin-left: 0.5rem;
      }

      .button {
        width: 100%;
      }
    }
  }

  .translation-container {
    width: 100%;
    height: calc(100vh - 68px - 60px - 70px - 5.25rem);
    max-height: calc(100vh - 68px - 60px - 70px - 5.25rem);
    min-height: 300px;
    margin-bottom: 1rem;
    font-size: 0.8rem;

    display: flex;
    flex-direction: row;

    .code-full-container {
      width: calc(50% - 0.5rem);
      overflow: hidden;

      &:first-child {
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-left: 0.5rem;
      }

      &.last-child {
        margin-left: 0.5rem;
      }
    }

    .codemirror-container {
      border-radius: 8px !important;
      overflow: hidden;

      @include themed {
        border: 1px solid th("border-color");
      }
    }
  }

  .cm-editor {
    height: 100%;
    flex: 1;
    position: relative;

    @include themed {
      background: th("bg-panel");
    }
    .cm-gutters {
      @include themed {
        background: th("bg-panel");
      }
    }

    .cm-activeLine {
      background: none;
    }

    .cm-activeLineGutter {
      @include themed {
        background: th("bg");
      }
    }

    .cm-scroller {
      position: absolute !important;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;

      scrollbar-width: thin;

      @include themed {
        scrollbar-color: th("border-color") transparent;
      }
    }

    .cm-selectionLayer {
      z-index: 10 !important;

      .cm-selectionBackground {
        background: #866dff !important;
        opacity: 0.25;
      }
    }
  }

  .translate-buttons {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      height: 60px;
    }

    .translate-button {
      max-width: 50%;
      font-size: 1.5rem;
      flex: 1;
      margin-left: 0.25rem;
    }

    .dropdown {
      margin-right: 0.25rem;

      .dropdown-link {
        padding: 0 1rem;
      }
    }

    .model-button {
      min-width: 150px;
    }
  }

  .dropdown-menu {
    max-height: 310px;
  }
}

.copy-button-cointainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.copy-button {
  width: calc(100%);
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (max-width: 1079px) {
  .Translate {
    .translation-container {
      height: calc(100vh - 68px - 60px - 70px - 78px - 5.25rem);
      max-height: calc(100vh - 68px - 60px - 70px - 78px - 5.25rem);
    }

    .language-buttons {
      width: 100%;
      margin-bottom: 1rem;
      flex-direction: column;

      .file-type-selector {
        width: unset;
      }

      .button-div {
        width: 100%;
        text-align: left;
        justify-content: flex-start;

        &:first-child {
          margin: 0;
          margin-right: 0;
        }

        &:last-child {
          margin: 0;
          margin-top: 0.5rem;
        }
        .button {
          width: 100%;
        }
      }
    }

    .translate-buttons {
      .translate-button {
        max-width: unset;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .Translate {
    .translation-container {
      flex-direction: column;

      .code-full-container {
        width: 100%;

        &:first-child {
          margin: 0;
          margin-bottom: 0.5rem;
        }
        &:last-child {
          margin: 0;
        }
        &.last-child {
          margin: 0;
        }
      }

      .copy-button-cointainer {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
    }

    .translate-buttons {
      .translate-button {
        font-size: 1.15rem;
      }

      .model-button {
        min-width: 100px;
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .Translate {
    .translation-container {
      height: calc(100vh - 68px - 60px - 70px - 70px - 64px - 5.25rem);
      max-height: calc(100vh - 68px - 60px - 70px - 70px - 64px - 5.25rem);
    }
    .translate-buttons {
      margin-bottom: 0;
      .translate-button {
        font-size: 1rem;
      }
    }
  }
}
