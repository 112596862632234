.ButtonLink {
  border-radius: 10px;
  padding: 8px 16px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: 0.15s;

  &.primary {
    background: linear-gradient(-45deg, $primary-950, $primary-700);

    color: #fff;

    &:hover {
      background: linear-gradient(-45deg, $primary-800, $primary-700);
    }

    &:active {
      background: linear-gradient(-45deg, $primary-950, $primary-700);
    }
  }

  &.border-light {
    background: transparent;
    color: #fff;
    
    @include themed{
      border: 1px solid th("border-color");
    }

    &:hover {
      border: 1px solid #888;
    }

    &:active {
      color: $primary-600;

      border: 1px solid $primary-600;
    }
  }

  &.border-purple {
    background: transparent;
    color: #ddd;
    border: 1px solid $primary-600;

    &:hover {
      color: $primary-500;
      border: 1px solid $primary-600;
    }

    &:active {
      color: $primary-400;
      border: 1px solid $primary-400;
    }
  }
}
